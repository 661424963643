import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import { Switch } from "@mui/material";
import Cropper from "../../../components/singleUpload/imageCropper";
import "./sellmydevice.scss";
import { ColorPicker } from "../../../components/colorPicker/colorpicker";

const SectionTwo = () => {
  const [loading, setLoading] = useState(false);
  const [headerimageVal, setHeaderImageValue] = useState(null);
  const [columnimage1Val, setColumnImage1Value] = useState(null);
  const [columnimage2Val, setColumnImage2Value] = useState(null);
  const [columnimage3Val, setColumnImage3Value] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [titleColor, setTitleColor] = useState("#041C3F");
  const [paragraphColor, setParagraphColor] = useState("#041C3F");
  const [isFetchingData, setIsFetchingData] = useState(true);

  const sectiontwoSchema = yup.object({
    title: yup.string().nullable(),
    description: yup.string().nullable(),
    headerImage: yup.mixed().nullable(),
    columnImage1: yup.mixed().nullable().required("Column 1 image is required"),
    columnImage2: yup.mixed().nullable().required("Column 2 image is required"),
    columnImage3: yup.mixed().nullable().required("Column 3 image is required"),
    columnTitle1: yup
      .string()
      .nullable()
      .required("Column 1 title is required"),
    columnDescription1: yup
      .string()
      .nullable()
      .required("Column 1 description is required"),
    columnTitle2: yup
      .string()
      .nullable()
      .required("Column 2 title is required"),
    columnDescription2: yup
      .string()
      .nullable()
      .required("Column 2 description is required"),
    columnTitle3: yup
      .string()
      .nullable()
      .required("Column 3 title is required"),
    columnDescription3: yup
      .string()
      .nullable()
      .required("Column 3 description is required"),
    active: yup.boolean().nullable().required(""),
  });

  const handleCancel = () => {
    refetch();
  };

  const handleHeaderImage = (croppedImage) => {
    setHeaderImageValue(croppedImage);
    setValue("headerImage", croppedImage);
  };

  const handleColumnImage1 = (croppedImage) => {
    setColumnImage1Value(croppedImage);
    setValue("columnImage1", croppedImage);
  };
  const handleColumnImage2 = (croppedImage) => {
    setColumnImage2Value(croppedImage);
    setValue("columnImage2", croppedImage);
  };

  const handleColumnImage3 = (croppedImage) => {
    setColumnImage3Value(croppedImage);
    setValue("columnImage3", croppedImage);
  };

  const defaults = {
    title: "",
    description: "",
    active: true,
    headerImage: null,
    columnImage1: null,
    columnImage2: null,
    columnImage3: null,
    columnTitle1: "",
    columnDescription1: "",
    columnTitle2: "",
    columnDescription2: "",
    columnTitle3: "",
    columnDescription3: "",
    titleColor: "#041C3F", // Default to black
    paragraphColor: "#041C3F", // Default to black
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sectiontwoSchema),
    defaultValues: defaults, // Set default values here
  });

  // Get Section Details by Slug
  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/SELL_MY_DEVICE_SECTION_TWO",
  });
  const sectionTwoDetailsArray = sectionDetails?.data?.data;

  useEffect(() => {
    if (sectionTwoDetailsArray) {
      setIsFetchingData(true);

      const getObject = (key) =>
        sectionTwoDetailsArray?.columns?.find(
          (item) => item[key] && item.language === storeLanguage
        );

      const isActive = sectionTwoDetailsArray?.columns?.find(
        (item) => item["is_enable"] && item?.language === "en"
      );

      const titleObject = getObject("title");
      const descriptionObject = getObject("description");
      const titleColorObject = getObject("title_color");
      const paragraphColorObject = getObject("paragraph_color");
      const activeObject = isActive;
      const imageObject = getObject("image");
      const columnimage1Object = getObject("column_1_image");
      const columntitle1Object = getObject("column_1_title");
      const columndescription1Object = getObject("column_1_description");
      const columnimage2Object = getObject("column_2_image");
      const columntitle2Object = getObject("column_2_title");
      const columndescription2Object = getObject("column_2_description");
      const columnimage3Object = getObject("column_3_image");
      const columntitle3Object = getObject("column_3_title");
      const columndescription3Object = getObject("column_3_description");

      const populatedFormData = {
        title: titleObject?.title,
        description: descriptionObject?.description,
        titleColor: titleColorObject?.title_color,
        paragraphColor: paragraphColorObject?.paragraph_color,
        active: activeObject?.is_enable,
        headerImage: imageObject?.image,
        columnImage1: columnimage1Object?.column_1_image,
        columnTitle1: columntitle1Object?.column_1_title,
        columnDescription1: columndescription1Object?.column_1_description,
        columnImage2: columnimage2Object?.column_2_image,
        columnTitle2: columntitle2Object?.column_2_title,
        columnDescription2: columndescription2Object?.column_2_description,
        columnImage3: columnimage3Object?.column_3_image,
        columnTitle3: columntitle3Object?.column_3_title,
        columnDescription3: columndescription3Object?.column_3_description,
      };

      console.log(populatedFormData);

      // Setting form values using setValue
      Object.keys(populatedFormData).forEach((key) => {
        setValue(key, populatedFormData[key]);
      });

      setTitleColor(populatedFormData.titleColor);
      setParagraphColor(populatedFormData.paragraphColor);

      setIsFetchingData(false);
    }
  }, [sectionTwoDetailsArray, storeLanguage, setValue]);

  console.log(storeLanguage);

  const handleSubmitForm = async (values) => {
    let formData = new FormData();
    setLoading(true);

    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("image", values.headerImage);
    formData.append("column_1_image", values.columnImage1);
    formData.append("column_1_title", values.columnTitle1);
    formData.append("column_1_description", values.columnDescription1);
    formData.append("column_2_image", values.columnImage2);
    formData.append("column_2_title", values.columnTitle2);
    formData.append("column_2_description", values.columnDescription2);
    formData.append("is_enable", values.active ? 1 : 0);
    formData.append("column_3_image", values.columnImage3);
    formData.append("column_3_title", values.columnTitle3);
    formData.append("column_3_description", values.columnDescription3);
    formData.append("language", storeLanguage);
    formData.append("title_color", titleColor);
    formData.append("paragraph_color", paragraphColor);

    if (values.columnImage1 instanceof File === false) {
      formData.delete("column_1_image");
    }
    if (values.columnImage2 instanceof File === false) {
      formData.delete("column_2_image");
    }
    if (values.columnImage3 instanceof File === false) {
      formData.delete("column_3_image");
    }

    if (values?.headerImage instanceof File === false) {
      formData.delete("image");
    }

    updateSectionone("SELL_MY_DEVICE_SECTION_TWO", formData).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };

  return (
    <div className="sectiontwo">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width={100} height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div className="switch">
                        <label>Enable this block</label>
                        <Switch
                          checked={value}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                        />
                      </div>
                    )}
                    name="active"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="title"
                        label="Title"
                        hideVariant
                        placeholder={"Enter Title"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="title"
                    control={control}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={120} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormTextArea
                        required
                        hideVariant
                        name="description"
                        label="Description"
                        placeholder={"Enter Description"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <ColorPicker
                  label="Title Color"
                  selectedColor={titleColor}
                  onColorChange={setTitleColor}
                  isLoading={isFetchingData}
                />
              </Grid>

              <Grid item xs={12}>
                <ColorPicker
                  label="Paragraph Color"
                  selectedColor={paragraphColor}
                  onColorChange={setParagraphColor}
                  isLoading={isFetchingData}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Background Image
            </Typography>
            <Grid item xs={12}>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box>
                    <Cropper
                      required
                      sendCroppedFile={handleHeaderImage}
                      MAX_IMAGE_WIDTH={1000}
                      MAX_IMAGE_HEIGHT={400}
                      title={"Drag your Background Image here only webp"}
                      className="width100"
                      iwebp={true}
                      showDeleteIcon={true}
                      isTrue={false}
                      imagwidth="width10"
                      imageSrc={getValues("headerImage")}
                    />
                    {error && value == null ? (
                      <p className="error">{error.message}</p>
                    ) : null}
                  </Box>
                )}
                name="headerImage"
                control={control}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ paddingLeft: "32px" }}>
            <Grid item xs={12} sm={4} style={{ marginTop: "20px" }}>
              <Typography variant="h6" gutterBottom>
                Column 1
              </Typography>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box>
                    <Cropper
                      required
                      sendCroppedFile={handleColumnImage1}
                      MAX_IMAGE_WIDTH={100}
                      MAX_IMAGE_HEIGHT={100}
                      isTrue={true}
                      iwebp={true}
                      imagwidth="width50"
                      showTitle={false}
                      imageSrc={getValues("columnImage1")}
                    />
                    {error && value == null ? (
                      <p className="error">{error.message}</p>
                    ) : null}
                  </Box>
                )}
                name="columnImage1"
                control={control}
              />
              <Controller
                name="columnTitle1"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    hideVariant
                    required
                    label="Title"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="columnDescription1"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormTextArea
                    required
                    hideVariant
                    label="Description"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} style={{ marginTop: "20px" }}>
              <Typography variant="h6" gutterBottom>
                Column 2
              </Typography>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box>
                    <Cropper
                      required
                      sendCroppedFile={handleColumnImage2}
                      MAX_IMAGE_WIDTH={200}
                      MAX_IMAGE_HEIGHT={200}
                      isTrue={true}
                      iwebp={true}
                      imagwidth="width50"
                      showTitle={false}
                      imageSrc={getValues("columnImage2")}
                    />
                    {error && value == null ? (
                      <p className="error">{error.message}</p>
                    ) : null}
                  </Box>
                )}
                name="columnImage2"
                control={control}
              />
              <Controller
                name="columnTitle2"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    hideVariant
                    required
                    label="Title"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="columnDescription2"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormTextArea
                    required
                    hideVariant
                    label="Description"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} style={{ marginTop: "20px" }}>
              <Typography variant="h6" gutterBottom>
                Column 3
              </Typography>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box>
                    <Cropper
                      required
                      sendCroppedFile={handleColumnImage3}
                      MAX_IMAGE_WIDTH={200}
                      MAX_IMAGE_HEIGHT={200}
                      isTrue={true}
                      iwebp={true}
                      imagwidth="width50"
                      showTitle={false}
                      imageSrc={getValues("columnImage3")}
                    />
                    {error && value == null ? (
                      <p className="error">{error.message}</p>
                    ) : null}
                  </Box>
                )}
                name="columnImage3"
                control={control}
              />
              <Controller
                name="columnTitle3"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    hideVariant
                    required
                    label="Title"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="columnDescription3"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormTextArea
                    required
                    hideVariant
                    label="Description"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                onClick={() => handleCancel()}
                name={"Cancel"}
                type="button"
                className="mx-1"
              />
            </Grid>
            <Grid item>
              <Button
                selected
                name={"Save"}
                type="submit"
                loading={loading}
                className="mx-1"
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SectionTwo;

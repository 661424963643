// EXTERNAL LIBRARIES
import React, { useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableCell, Paper , Button} from "@mui/material";
import { AlertContext } from "../../context/AlertContext";
// TABLE
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import Paginator from "../../components/paginator/Paginator";

// CUSTOM HOOKS
import { useGet } from "../../hooks/useFetch";

// DATA NOT FOUND
import DataNotFound from "../../components/notFound/DataNotFound";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { deleteProductSku } from "../../core/apis/productSkus";
import withH1 from "../../assests/HOC/withH1";

const ProductSKU = () => {
  // HOOKS AND DATA
  const { setAlert } = useContext(AlertContext);
  const [searchParams] = useSearchParams();
  const [detail, setDetail] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  let navigate = useNavigate();

  const tableHeaders = [
    { title: "SKU-Details" },
    { title: "SKU" },
    { title: "Quantity" },
    { title: "Status" },
  ];

  let payload = {
    per_page: 30,
    page: searchParams.get("page") || 1,
    sku_code: searchParams.get("search"),
  };

  // Fetching the data to be displayed
  let url = `/admin/get-all-product-skus`;
  const {
    data: productSKU,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = productSKU?.data?.data;

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteProductSku(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };
  return (
    <div className="productskuList">
      <Paper elevation={24} className="paper-div">
        <TableHeader
          to="/product-sku/new-product-sku"
          placeholder="Search by product SKU or by product name"
          filterButton={false}
        />
        <TableBodyComponent
          loading={loading}
          header={tableHeaders}
          total={data?.total}
        >
          {data?.data?.length ? (
            data?.data?.map((element) => (
              <TableRowComponent
                internal
                // handleDelete={() => {
                //   setDetail(element);
                //   setOpenDelete(true);
                // }}
                handleEdit={() =>
                  navigate("/product-sku/edit-product-sku/" + element?.id)
                }
                handleEye={() =>
                  navigate("/product-sku/view-product-sku/" + element?.id)
                }
                row={element}
                key={element?.id}
              >
                <TableCell>
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      element?.product?.category?.image_path
                    }
                    style={{ height: "50px", width: "50px" }}
                  />
                  {element?.name}
                </TableCell>
                <TableCell>{element?.sku_code}</TableCell>
                <TableCell>{element?.item_count}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color={element.active ? "success" : "error"}
                    
                  >
                    {element?.active ? "LIVE" : "NOT LIVE"}
                  </Button>
                </TableCell>
              </TableRowComponent>
            ))
          ) : (
            <DataNotFound />
          )}
        </TableBodyComponent>
      </Paper>
      {data && <Paginator count={data?.last_page} disabled={loading} />}
      {openDelete && (
        <NoticeConfirmation
          data={detail}
          handleResponse={handleDeleteResponse}
        />
      )}
    </div>
  );
};
export default withH1(ProductSKU, "Product SKU");

import { api } from "./main";

export const getAllProductSkus = async (payload) => {
  return await api
    .get(`admin/get-all-product-skus`, { params: { ...payload } })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getProductSkusByID = async (payload) => {
  return await api
    .get(`admin/get-product-sku-by-id/${payload?.id}`, {
      params: { ...payload },
    })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const createItemProductSku = async (payload) => {
  return await api
    .post(`admin/create-item-product-sku`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const createProductSku = async (payload) => {
  return await api
    .post(`admin/create-item-product-sku`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateProductSku = async (payload) => {
  return await api
    .put(`admin/update-product-sku/${payload?.id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteProductSku = async (payload) => {
  return await api
    .delete(`admin/delete-product-sku/${payload}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Grid } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import { useGlobalValues } from "../../../context/GlobalContext";
import { AlertContext } from "../../../context/AlertContext";
import Cropper from "../../../components/singleUpload/imageCropper";
import "./about.scss";
import { useGet } from "../../../hooks/useFetch";
import ViewMode from "./viewMode";
import {
  updateDynamicSection,
  deleteDynamicSection,
  getSectionbySlug,
  updateSectionone,
} from "../../../core/apis/store";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import {
  findValue,
  checkFirstValueLanguage,
  combineByLanguage,
} from "../../../core/functions/Functions";
import CustomCard from "../../../components/loadingSkeltons/cardskelton";
import NewEditor from "../../../components/editor/NewEditor";

const AboutusSection = () => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [addsections, setAddSections] = useState(false); // State to manage multiple sections
  const [sectionLength, setsectionLength] = useState(0);
  const [arabicsectionLength, setarabicsectionLength] = useState(0);

  const [openDelete, setOpenDelete] = useState(false);
  const [create, setcreate] = useState(true);
  const [slug, setSlug] = useState("");
  const [combinedData, setCombinedData] = useState([]);

  // Schema validation
  const informationsectionSchema = yup.object({
    title: yup.string().nullable().required("Field is required"),
    description: yup.string().nullable().required("Field is required"),
    image: yup.mixed().nullable().required("Field is required"),
    order: yup.string().nullable(),
  });

  const {
    control,
    setValue,
    getValues,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(informationsectionSchema),
    defaultValues: {
      title: "",
      description: "",
      image: null,
      order: "",
    },
  });

  // Get Section Details by Slug
  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/ABOUT_US_SECTION_ONE",
  });
  const aboutusSectionOne = sectionDetails?.data?.data;

  useEffect(() => {
    if (aboutusSectionOne) {
      // Get the count of objects in English and Arabic
      const { englishCount, arabicCount } =
        checkFirstValueLanguage(aboutusSectionOne);
      setsectionLength(englishCount);
      setarabicsectionLength(arabicCount);

      // Combine objects by language
      const combinedData = combineByLanguage(aboutusSectionOne);
      setCombinedData(combinedData);
    }
  }, [aboutusSectionOne, storeLanguage]);

  useEffect(() => {
    refetch();
  }, [storeLanguage]);

  const handleHeaderImage = (croppedImage) => {
    setValue("image", croppedImage);
  };

  const handleAddNewSection = () => {
    setAddSections(!addsections);
  };

  const handleCancel = () => {
    setAddSections(false);
    reset({
      title: "",
      description: "",
      image: null,
      order: "",
    });
  };

  const handleSubmitForm = async (values) => {
    let formData = new FormData();
    setLoading(true);
    let order =
      storeLanguage === "en" ? sectionLength + 1 : arabicsectionLength + 1;
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("image", values.image);
    formData.append("language", storeLanguage);
    formData.append("order", create ? order : values.order);

    if (values?.image instanceof File === false) {
      formData.delete("image");
    }

    if (create) {
      updateDynamicSection("ABOUT_US_SECTION_ONE", formData).then((res) => {
        setAlert({
          visible: true,
          text: res?.data.success ? "Successfully Created" : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        refetch();
        setAddSections(false);
        setLoading(false);
      });
    } else {
      updateSectionone(slug, formData).then((res) => {
        setAlert({
          visible: true,
          text: res?.data.success ? "Successfully Updated" : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        refetch();
        setAddSections(false);
        setLoading(false);
      });
    }
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteDynamicSection(responseData).then((res) => {
        setAlert({
          visible: true,
          text: res?.data?.success
            ? "Deleted SuccessFully"
            : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setSlug("");
        refetch();
      });
    }
    setOpenDelete(false);
  };
  const handleEdit = (item) => {
    setSlug(item);
    setAddSections(!addsections);
    setcreate(false);
  };

  useEffect(() => {
    if (!create) {
      getSectionbySlug(slug).then((res) => {
        if (res?.data?.success) {
          setValue(
            "title",
            findValue("title", res?.data?.data[0]?.columns, storeLanguage)
          );
          setValue(
            "description",
            findValue("description", res?.data?.data[0]?.columns, storeLanguage)
          );
          setValue(
            "image",
            findValue("image", res?.data?.data[0]?.columns, storeLanguage)
          );
          setValue(
            "order",
            findValue("order", res?.data?.data[0]?.columns, storeLanguage)
          );
        }
      });
    }
  }, [create]);

  const watchdata = watch("description", true);

  return (
    <div className="aboutusSection">
      <div className="viewModeHeader">
        {!addsections && (
          <Button
            selected
            name="Add New About us Section"
            onClick={(e) => handleAddNewSection()}
          />
        )}
      </div>
      {!addsections && (
        <div className="viewMode">
          {loadingDetails ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CustomCard />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              {combinedData[storeLanguage || "en"]?.map((item) => (
                <Grid item xs={12} sm={6} md={3} key={item.slug}>
                  <ViewMode
                    data={item}
                    language={storeLanguage}
                    onDelete={() => {
                      setSlug(item.slug);
                      setOpenDelete(true);
                    }}
                    onEdit={() => {
                      handleEdit(item.slug);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      )}

      {addsections && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name={`title`}
                        label={`Title`}
                        hideVariant
                        placeholder={`Enter Title`}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name={`title`}
                    control={control}
                    defaultValue=""
                  />
                </Grid>

                <Grid item xs={12}>
                  {/* <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormTextArea
                        hideVariant
                        name={`description`}
                        label={`Description`}
                        placeholder="Enter Details"
                        value={value}
                        rows={6}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name={`description`}
                    control={control}
                    defaultValue=""
                  /> */}

                  {!create && watchdata && (
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <NewEditor
                          label={"Description"}
                          placeholder="Enter Details"
                          value={value}
                          lang={storeLanguage}
                          onChange={(e) => onChange(e)}
                        />
                      )}
                      name="description"
                      control={control}
                    />
                  )}

                  {create && (
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <NewEditor
                          label={"Description"}
                          placeholder="Enter Details"
                          value={value}
                          lang={storeLanguage}
                          onChange={(e) => onChange(e)}
                        />
                      )}
                      name="description"
                      control={control}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Box>
                        <Cropper
                          required
                          sendCroppedFile={handleHeaderImage}
                          MAX_IMAGE_WIDTH={700}
                          MAX_IMAGE_HEIGHT={400}
                          title={"Drag your Image here only webp"}
                          className="width100"
                          isTrue={true}
                          iwebp={true}
                          imageSrc={value}
                        />
                        {error && value == null ? (
                          <p className="error">{error.message}</p>
                        ) : null}
                      </Box>
                    )}
                    name={`image`}
                    control={control}
                    defaultValue={null}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            style={{ marginTop: "20px" }}
          >
            <Grid item>
              <Button
                onClick={() => handleCancel()}
                name={"Cancel"}
                type="button"
                className="mx-1"
              />
            </Grid>
            <Grid item>
              <Button
                selected
                name={"Save"}
                type="submit"
                className="mx-1"
                loading={loading}
              />
            </Grid>
          </Grid>
        </form>
      )}

      {openDelete && (
        <NoticeConfirmation data={slug} handleResponse={handleDeleteResponse} />
      )}
    </div>
  );
};

export default AboutusSection;

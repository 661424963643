import React, { useState, useContext, useEffect } from "react";
import { AlertContext } from "../../../context/AlertContext";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Skeleton from "@mui/material/Skeleton";
import {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import "./createPartner.scss";
import Cropper from "../../../components/singleUpload/imageCropper";
import { Switch, FormControlLabel, Typography } from "@mui/material";
import Button from "../../../components/button/Button";
import { useGlobalValues } from "../../../context/GlobalContext";
import {
  createPartner,
  getPartnersByID,
  updatePartner,
} from "../../../core/apis/store";

const CreatePartners = (props) => {
  const { onCancel, onSave, ID } = props;
  const [loading, setLoading] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true); // New state for loading while fetching data
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();

  const createPartnerSchema = yup.object({
    title: yup.string().nullable().required("Field is required"),
    description: yup.string().nullable().required("Field is required"),
    ColoredImage: yup.mixed().required("Field is required"),
    GreyImage: yup.mixed().required("Field is required"),
    link: yup.string().nullable().required("Field is required"),
    active: yup.bool(),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createPartnerSchema),
  });
  const handleCancel = () => {
    onCancel();
  };

  // Fetch data and populate form if ID is provided
  useEffect(() => {
    if (ID) {
      setIsFetchingData(true); // Start fetching data
      getPartnersByID(ID).then((res) => {
        if (res?.data?.success) {
          const partnerData = res?.data?.data[0];
          const populatedFormData = {
            title: partnerData?.title?.[storeLanguage],
            link: partnerData?.link,
            description: partnerData?.description?.[storeLanguage],
            ColoredImage: partnerData?.colored_image_path,
            GreyImage: partnerData?.gray_image_path,
            active: partnerData?.is_active,
          };

          setValue("title", populatedFormData.title);
          setValue("link", populatedFormData.link);
          setValue("description", populatedFormData.description);
          setValue("ColoredImage", populatedFormData.ColoredImage);
          setValue("GreyImage", populatedFormData.GreyImage);
          setValue("active", populatedFormData.active);
        }
        setIsFetchingData(false); // Stop fetching data
      });
    } else {
      setIsFetchingData(false); // If there's no ID, stop loading immediately
    }
  }, [ID, storeLanguage, setValue]);

  const handleSubmitForm = async (values) => {
    console.log(values);
    let formData = new FormData();
    setLoading(true);

    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("language", storeLanguage);
    formData.append("colored_image", values.ColoredImage);
    formData.append("gray_image", values.GreyImage);
    formData.append("link", values.link);
    formData.append("is_enabled", values.active ? 1 : 0);

    if (ID) {
      if (!(values?.ColoredImage instanceof File)) {
        formData.delete("colored_image");
      }
      if (!(values?.GreyImage instanceof File)) {
        formData.delete("gray_image");
      }

      updatePartner(ID, formData).then((res) => {
        onSave();
        setAlert({
          visible: true,
          text: res?.data.success ? "Successfully Updated" : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setLoading(false);
      });
    } else {
      createPartner(formData).then((res) => {
        onSave();
        setAlert({
          visible: true,
          text: res?.data.success ? "Successfully Created" : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setLoading(false);
      });
    }
  };

  const [imageVal, setColoredImageValue] = useState(null);
  const [imageGreyVal, setGreyImageValue] = useState(null);

  const handleColoredImage = (croppedImage) => {
    setColoredImageValue(croppedImage);
    setValue("ColoredImage", croppedImage);
  };

  const handleGreyImage = (croppedImage) => {
    setGreyImageValue(croppedImage);
    setValue("GreyImage", croppedImage);
  };

  return (
    <div className="store-title partner-section">
      <Typography variant="h5" style={{ margin: "15px" }}>
        {ID ? "Edit Partner" : "New Partner"}
      </Typography>
      {isFetchingData ? (
        <div className="flexcontainer">
          {/* Skeleton Loaders */}
          <div className="form-section">
            {/* Title Skeleton */}
            <Skeleton
              variant="rectangular"
              width="100%"
              height={56}
              style={{ marginBottom: "16px" }}
            />

            {/* Description Skeleton */}
            <Skeleton
              variant="rectangular"
              width="100%"
              height={120}
              style={{ marginBottom: "16px" }}
            />

            {/* Active Switch Skeleton */}
            <Skeleton
              variant="rectangular"
              width={100}
              height={40}
              style={{ marginBottom: "16px" }}
            />
          </div>

          {/* Image Upload Skeletons */}
          <div className="image-section">
            {/* Grey Image Skeleton */}
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              style={{ marginBottom: "16px" }}
            />

            {/* Colored Image Skeleton */}
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              style={{ marginBottom: "16px" }}
            />

            {/* Button Skeleton */}
            <div className="action-footer">
              <Skeleton
                variant="rectangular"
                width={120}
                height={40}
                style={{ marginRight: "16px" }}
              />
              <Skeleton variant="rectangular" width={120} height={40} />
            </div>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="flexcontainer">
            <div className="form-section">
              {/* Title Field */}
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    required
                    name="title"
                    label="Title"
                    hideVariant
                    placeholder={"Enter Title"}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    helperText={error?.message}
                  />
                )}
                name="title"
                control={control}
              />

              {/* Description Field */}
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormTextArea
                    required
                    name="description"
                    label="Description"
                    className="labelclassname"
                    placeholder={"Enter Description"}
                    value={value}
                    hideVariant
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    helperText={error?.message}
                  />
                )}
                name="description"
                control={control}
              />
              {/* Link Field */}
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    required
                    name="link"
                    label="Link"
                    hideVariant
                    placeholder={"Enter Link"}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    helperText={error?.message}
                  />
                )}
                name="link"
                control={control}
              />

              {/* Active Switch */}
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    label="Active"
                    control={<Switch checked={value} />}
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                  />
                )}
                name="active"
                control={control}
              />
            </div>

            {/* Image Section */}
            <div className="image-section">
              {/* Grey Image Cropper */}
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div>
                    <Cropper
                      required
                      sendCroppedFile={handleGreyImage}
                      MAX_IMAGE_WIDTH={300}
                      MAX_IMAGE_HEIGHT={300}
                      title={"Drag your Grey Image here "}
                      className="width100"
                      isTrue={true}
                      iwebp={true}
                      imageSrc={getValues("GreyImage")}
                    />
                    {error && imageGreyVal == null ? (
                      <p className="error">{error.message}</p>
                    ) : null}
                  </div>
                )}
                name="GreyImage"
                control={control}
              />

              {/* Colored Image Cropper */}
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div>
                    <Cropper
                      required
                      sendCroppedFile={handleColoredImage}
                      MAX_IMAGE_WIDTH={300}
                      MAX_IMAGE_HEIGHT={300}
                      title={"Drag your Colored Image here only webp"}
                      className="width100"
                      isTrue={true}
                      iwebp={true}
                      imageSrc={getValues("ColoredImage")}
                    />
                    {error && imageVal == null ? (
                      <p className="error">{error.message}</p>
                    ) : null}
                  </div>
                )}
                name="ColoredImage"
                control={control}
              />

              {/* Action Buttons */}
              <div className="action-footer">
                <Button
                  selected
                  name={"Save"}
                  type="submit"
                  loading={loading}
                  className="mx-1"
                />
                <Button
                  onClick={() => handleCancel()}
                  name={"Cancel"}
                  type="button"
                  className="mx-1"
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default CreatePartners;
